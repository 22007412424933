<template>
  <b-row class="match-height">
    <b-col lg="8">
      <RecruitExplain />
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import RecruitExplain from '@/components/basic/about/RecruitExplain.vue'

export default {
  components: {
    BRow,
    BCol,
    RecruitExplain,
  },
}
</script>

<template>
  <div>
    <h2>スタッフ募集</h2>
    <div class="mb-1">
      テキストテキストテキストテキストテキストテキストテキストテキスト<br>
      テキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト
    </div>
    <b-card>
      <b-card
        title=""
        img-src="@/assets/images/sample/sample2.png"
      >
        <small>※skillism用のサンプル画像です。</small>
      </b-card>
      <b-alert
        variant="primary"
        show
      >
        <div class="alert-body mt-1 mb-2">
          テキストテキストテキストテキストテキストテキストテキストテキスト
        </div>
      </b-alert>
      <div class="">
        テキストテキストテキストテキストテキストテキストテキストテキスト<br>
        テキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト
      </div>
    </b-card>
    <h2>テキスト</h2>
    <div class="mb-1">
      <p>テキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト</p>
      <p>テキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト</p>
      <p>テキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト</p>
    </div>
    <b-alert
      variant="primary"
      show
    >
      <div class="alert-body mt-1 mb-2">
        テキストテキストテキストテキストテキストテキストテキストテキスト
      </div>
    </b-alert>
    <h2>テキスト</h2>
    <div class="mb-1">
      テキストテキストテキストテキストテキストテキストテキストテキスト<br>
      テキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト
    </div>
    <b-card>
      <b-card
        title=""
        img-src="@/assets/images/sample/sample2.png"
      >
        <small>※skillism用のサンプル画像です。</small>
      </b-card>
      <b-alert
        variant="primary"
        show
      >
        <div class="alert-body mt-1 mb-2">
          テキストテキストテキストテキストテキストテキストテキストテキスト
        </div>
      </b-alert>
      <div class="">
        テキストテキストテキストテキストテキストテキストテキストテキスト<br>
        テキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト
      </div>
    </b-card>
    <h2>テキスト</h2>
    <div class="mb-1">
      <p>テキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト</p>
      <p>テキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト</p>
      <p>テキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト</p>
    </div>
  </div>
</template>

<script>
import {
  BCard, BAlert,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BAlert,
  },
  directives: {
  },
  props: {
  },
  data() {
    return {
    }
  },
  methods: {
  },
}
</script>
